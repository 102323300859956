import React from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Card,
  CardContent,
  Link,
  ButtonGroup,
  Chip,
  Toolbar,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import {
  ThumbDown,
  ThumbUp,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

function Center({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}

function ResultRenderer({
  result,
  loading,
}: {
  result: string;
  loading: boolean;
}) {
  return (
    <Typography
      maxWidth="600px"
      color="lightGray"
      sx={{
        // typewriter effect when loading
        "&::after": {
          content: loading ? '"|"' : '""',
          animation: loading ? "$typewriter 1s steps(1) infinite" : "none",
        },
        "@keyframes typewriter": {
          from: { width: 0 },
          to: { width: "100%" },
        },
      }}
    >
      {loading ? (
        result
      ) : (
        <ReactMarkdown
          // link component
          components={{
            a: ({ node, ...props }) => (
              <Link
                {...props}
                // open links in new tab
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        >
          {result}
        </ReactMarkdown>
      )}
    </Typography>
  );
}

function App() {
  const { t } = useTranslation();

  const [query, setQuery] = React.useState<string>("");
  const [followUpQuery, setFollowUpQuery] = React.useState<string>("");
  const [followUpLoading, setFollowUpLoading] = React.useState<boolean>(false);
  const [followUpResult, setFollowUpResult] = React.useState<string>("");

  const [result, setResult] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sources, setSources] = React.useState<any>([]);
  const [cited, setCited] = React.useState<boolean>(false);
  const [citedSources, setCitedSources] = React.useState<any>([]);
  const [recommendations, setRecommendations] = React.useState<string[]>([]);
  const [emptyRecommendations, setEmptyRecommendations] = React.useState<
    string[]
  >([]);
  const [queryHistory, setQueryHistory] = React.useState<string[]>([]);
  const [rippleEffect, setRippleEffect] = React.useState<boolean>(false);
  const country = sessionStorage.getItem("country") || navigator.language;
  const apiEndpoint =
    process.env.NODE_ENV === "development"
      ? "http://localhost:5000"
      : "https://api.algea.xyz";

  // Feedback related
  const [voted, setVoted] = React.useState<string>("");

  function updateQueryHistory(query: string) {
    const parsedHistory = JSON.parse(
      sessionStorage.getItem("queryHistory") || "[]"
    );
    if (parsedHistory.includes(query)) {
      parsedHistory.splice(parsedHistory.indexOf(query), 1);
    }

    parsedHistory.push(query);
    if (parsedHistory.length > 7) {
      parsedHistory.shift();
    }
    sessionStorage.setItem("queryHistory", JSON.stringify(parsedHistory));
    setQueryHistory(parsedHistory);
  }

  async function search() {
    if (query === "") {
      alert("Please enter something to search");
      return;
    }
    setCited(false);
    setCitedSources([]);
    setVoted("");
    setRippleEffect(false);
    setFollowUpLoading(false);
    setFollowUpResult("");
    setFollowUpQuery("");
    setLoading(true);
    setResult("");
    setSources([]);
    // Append to query history
    updateQueryHistory(query);

    const response = new EventSource(
      apiEndpoint +
        "/search?query=" +
        encodeURIComponent(query) +
        "&country=" +
        country +
        "&aid=" +
        algeaId()
    );
    response.addEventListener("answer", (e) => {
      setResult((prev) => prev + e.data);
    });
    response.addEventListener("sources", (e) => {
      setSources(JSON.parse(e.data));
    });
    response.onerror = (e) => {
      setLoading(false);
      response.close();
    };
  }

  function followUp() {
    setFollowUpLoading(true);
    setFollowUpResult("");
    const response = new EventSource(
      apiEndpoint +
        "/followup?original_question=" +
        query +
        "&original_answer=" +
        result +
        "&followup_question=" +
        followUpQuery +
        "&country=" +
        country +
        "&aid=" +
        algeaId()
    );
    response.addEventListener("answer", (e) => {
      setFollowUpResult((prev) => prev + e.data);
    });
    response.onerror = (e) => {
      setFollowUpLoading(false);
      response.close();
    };
  }

  async function searchRecommendations() {
    const response = await axios.get(
      apiEndpoint +
        "/recommendations?query=" +
        query +
        "&country=" +
        country +
        "&aid=" +
        algeaId()
    );
    setRecommendations(response.data);
    if (query === "") {
      setEmptyRecommendations(response.data);
    }
  }

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      searchRecommendations();
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [query]);

  React.useEffect(() => {
    setQueryHistory(JSON.parse(sessionStorage.getItem("queryHistory") || "[]"));
    algeaId();
  }, []);

  const explore = React.useCallback((query: string) => {
    setQuery("");
    setRippleEffect(false);

    for (let i = 0; i < query.length; i++) {
      setTimeout(() => {
        setQuery((prev) => prev + query[i]);
      }, 60 * i);
    }

    setTimeout(() => {
      setRippleEffect(true);
    }, 60 * query.length);
  }, []);

  function algeaId(): string {
    // Assign user ID if does not exist
    // This is anonymous and is used for analytics
    if (!localStorage.getItem("algeaId")) {
      localStorage.setItem("algeaId", uuidv4());
      window.analytics.identify(localStorage.getItem("algeaId"));
    }

    return localStorage.getItem("algeaId") || "";
  }

  function reset() {
    setVoted("");
    setQuery("");
    setRippleEffect(false);
    setResult("");
    setFollowUpResult("");
    setFollowUpQuery("");
    setFollowUpLoading(false);
    setLoading(false);
    setSources([]);
    setCited(false);
    setCitedSources([]);
  }

  function feedback(positive: boolean) {
    axios.post(
      apiEndpoint + "/feedback?aid=" + algeaId(),
      {
        query,
        result,
        feedback: positive,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setVoted(positive ? "positive" : "negative");
  }

  function convertToLinks() {
    // Convert source references like [1] in the answer to links
    // in Markdown. This is done after the answer is received
    // from the server.
    if (cited || loading) {
      return;
    }

    let newResult = result;

    // Find [0], [1] etc. in result
    const regex = /\[[0-9]+\]/g;
    const matches = result.match(regex);

    if (matches) {
      matches.forEach(async (match) => {
        const index = parseInt(match.replace("[", "").replace("]", ""));
        const source = sources.find((s: any) => s.id === index);
        if (source) {
          setCitedSources((prev: any) => [...prev, source]);
          newResult = newResult.replace(match, `[${match}](${source.link})`);
        }
      });
    }
    setCited(true);
    setResult(newResult);
  }

  React.useEffect(() => {
    convertToLinks();

    // eslint-disable-next-line
  }, [result, loading]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <br />
          <br />
          <br />
          <Typography
            variant="h2"
            component="h1"
            fontWeight={800}
            textAlign="center"
            onClick={!loading ? () => reset() : undefined}
            sx={{
              cursor: "pointer",
            }}
          >
            Algea
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            fontWeight={600}
            textAlign="center"
          >
            {t("Algea Headline")}
          </Typography>
          <br />
          <br />
          <Center>
            <Autocomplete
              id="search"
              options={
                query
                  ? recommendations
                  : queryHistory
                  ? queryHistory.reverse()
                  : recommendations
              }
              sx={{
                marginBottom: 2,
                width: "100%",
                maxWidth: "600px",
                // styling
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
              title="Search"
              disableClearable
              freeSolo
              onChange={(e, value) => setQuery(value || "")}
              value={query}
              filterOptions={(options) => options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Search")}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: loading ? (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: 2,
                          marginLeft: 2,
                          textTransform: "none",
                        }}
                        color="secondary"
                        disabled
                      >
                        {t("Loading")}
                      </Button>
                    ) : (
                      <motion.div
                        animate={{
                          x: rippleEffect
                            ? [0, 10, -10, 10, -10, 10, -10, 0]
                            : 0,
                        }}
                        transition={{
                          duration: 0.5,
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: 2,
                            marginLeft: 2,
                            textTransform: "none",
                          }}
                          color="secondary"
                          onClick={search}
                        >
                          {t("Search")}
                        </Button>
                      </motion.div>
                    ),
                  }}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              )}
            />
          </Center>
          <Center>
            {result && (
              <div>
                <Toolbar disableGutters>
                  <Typography
                    variant="h5"
                    fontWeight={800}
                    sx={{ flexGrow: 1 }}
                  >
                    {t("Answer")}
                  </Typography>
                  <ButtonGroup>
                    <IconButton
                      disabled={voted !== ""}
                      color="success"
                      onClick={() => feedback(true)}
                    >
                      {voted === "positive" ? <ThumbUp /> : <ThumbUpOutlined />}
                    </IconButton>
                    <IconButton
                      disabled={voted !== ""}
                      color="error"
                      onClick={() => feedback(false)}
                    >
                      {voted === "negative" ? (
                        <ThumbDown />
                      ) : (
                        <ThumbDownOutlined />
                      )}
                    </IconButton>
                  </ButtonGroup>
                </Toolbar>
                <ResultRenderer result={result} loading={loading} />
                {!loading && (
                  <>
                    <br />
                    <TextField
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 2,
                        },
                      }}
                      label={t("Follow up question")}
                      variant="outlined"
                      value={followUpQuery}
                      onChange={(e) => setFollowUpQuery(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: 2,
                              marginLeft: 2,
                              textTransform: "none",
                            }}
                            onClick={followUp}
                            disabled={followUpLoading}
                            color="info"
                          >
                            {followUpLoading ? "Loading..." : "Ask"}
                          </Button>
                        ),
                      }}
                    />
                    {followUpResult && (
                      <>
                        <Typography variant="body2" pt={2} maxWidth="600px">
                          {followUpResult}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </Center>
          <br />
          {citedSources.length > 0 && (
            <Center>
              <Card
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  maxHeight: 200,
                  overflow: "auto",
                }}
                variant="outlined"
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    fontWeight={800}
                    color="lightGray"
                    pb={1.5}
                    sx={{ flexGrow: 1 }}
                  >
                    {t("Sources")}
                  </Typography>
                  {citedSources.map((source: any) => (
                    <Chip
                      icon={
                        <img
                          alt=""
                          src={
                            apiEndpoint +
                            "/favicon/" +
                            source.link.split("/")[2]
                          }
                          style={{
                            width: 16,
                            height: 16,
                            marginLeft: 8,
                            borderRadius: 2,
                          }}
                        />
                      }
                      label={source.id + ": " + source.title}
                      variant="outlined"
                      sx={{
                        marginRight: 1,
                        marginBottom: 1,
                      }}
                      onClick={() => window.open(source.link)}
                    />
                  ))}
                </CardContent>
              </Card>
            </Center>
          )}
          {!result && emptyRecommendations.length > 0 && (
            <>
              <Center>
                <Typography
                  variant="h6"
                  fontWeight={800}
                  pb={1}
                  textAlign="center"
                >
                  {t("Explore")}
                </Typography>
              </Center>
              <Center>
                <ButtonGroup
                  // vertical
                  orientation="vertical"
                  variant="text"
                >
                  {emptyRecommendations
                    .slice(
                      0,
                      emptyRecommendations.length > 3
                        ? 3
                        : emptyRecommendations.length
                    )
                    .map((query) => (
                      <Button
                        sx={{
                          borderRadius: 2,
                          marginLeft: 2,
                          textTransform: "none",
                        }}
                        color="secondary"
                        onClick={() => explore(query)}
                        key={query}
                      >
                        {query}
                      </Button>
                    ))}
                </ButtonGroup>
              </Center>
            </>
          )}
          <br />
          <br />
          {!result && (
            <Center>
              <Alert
                severity="info"
                sx={{
                  maxWidth: "600px",
                  borderRadius: 2,
                }}
                variant="outlined"
              >
                <AlertTitle>{t("Public preview")}</AlertTitle>
                {t("Public preview description")}
              </Alert>
            </Center>
          )}
          <br />
          <br />
        </Container>
      </Box>
    </>
  );
}

export default App;
